@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: 'jetBrains Mono';
}

img {
  user-select: none;
}

body{
  background:url(/public/fundo2.png)   repeat-y bottom center  #000;
  width:100%;
  height: 100vh;
  background-size:contain;
    width: 380px;
    height: 100vh;
    background-size: contain;
    margin: 0px auto;
}

html{

}

.bg-neutral-100 {
  background:url(/public/fundo2.png)   repeat-y bottom center;
  width:100%;
  height: 100vh;
  background-size:cover;

}

h1{
  display:none;
}

.iconpink{
  padding-left: 10%;
  font-size:14px;
  font-weight: bold;
}

.ring-1{
  background: url(/public/icon-black.png) center no-repeat;
  --tw-ring-color:none;
  color:#fff;
      line-height: 28px;
      background-size:cover;
      padding-left: 50px;
}

.ring-2{
  background: url(/public/icon-danny.png) center no-repeat;
  --tw-ring-color:none;
  color:#fff;
      line-height: 28px;
}

#capa{
  background: url(/public/danny-recorte.png) bottom center no-repeat;
  background-size:contain;
  width: 60%;
    height: 100vh;
  position: absolute;
  bottom:0px;
  right:0px;
  display:none;
}

#socials{
  bottom: 0px;
  position: absolute;
  margin-left: 30px;
}

a{
  z-index: 100;
}

#logodanny{
  width: 420px;
  border: none;
  --tw-ring-color: none;
}

